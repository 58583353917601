/* .favicon-color {
  background: #3DA473;
} */
/* ----------------------- NAVIGATION STYLING ----------------------- */
.navibar {
  text-align: center;
}
.menu-toggle {
  height: 30px;
  width: 30px;
}
/* ----------------------- HEADER STYLING ----------------------- */
.header-container {
  height: 600px;
  text-align: center;
}
.header-con-col-one {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-con-col-one h1 {
  animation: 3s fade-in;
}
.header-con-col-one h4 {
  margin-top: 20px;
  animation: 4s fade-in;
}
.header-img-box {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-img {
  height: 200px;
  width: auto;
  animation: 5s fade-in;

}
.header-img-tech {
  width: 100%;
  height: 100%;
}
@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* ----------------------- ABOUT STYLING ----------------------- */
.cool-about {
  width: 100%;
}
.about-container {
  height: 800px;
  text-align: center;
  padding: 15px;
}
.about-con-col-one {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  background-color: #282c34;
}

@media only screen and (max-width: 800px){
  .about-container {
    height: 100%;
    margin-top: 30px;
    margin-bottom: 30px ;
  }
}

/* ----------------------- PROJECTS STYLING ----------------------- */
.cool-Projects {
  width: 100%;
  background-color: #282c34;
}
.projects-container {
  height: 800px;
  text-align: center;
  padding: 15px;
}
.projects-con-col-one {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------------------- CERTIFICATIONS STYLING ----------------------- */
.cool-certifications {
  width: 100%;
  color: #282c34;
}
.certifications-container {
  height: 820px;
  text-align: center;
  padding: 15px;
}
.certifications-con-col-one {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cert-cards {
  align-items: center;
}

.cert-img {
  height: 200px;
  width: 200px;
}
@media only screen and (max-width: 800px){
  .certifications-container {
    height: 100%;
    margin-top: 30px;
  }
  .certifications-con-col-one {
    height: 830px;
  }
  .cert-img {
    height: 125px;
    width: auto
  }
}
